import { Box, styled } from "@mui/material";
import { InfoBlock } from "./InfoBlock";
import closeIcon from "../../img/close.svg";
import { useState } from "react";
import Typography from "@mui/material/Typography";

export function SubscriptionCard(props) {
    const { contract, setSelectedContract, setNewContractId, closeIconNeeded, titleNeeded, contractLabelNeeded, setShowSelectedContract } = props;
    const [isVisible, setIsVisible] = useState(true);
    let emptyContract = {currentIntegrationsCount: 0, integrationsLimit: 0, endDate: Date.now()}

    const handleStatusClick = () => {
        if (closeIconNeeded) {
            setSelectedContract(null);
            setNewContractId(null);
        }
        setIsVisible(false);
    };

    const selectContract = () => {
        if (!closeIconNeeded) {
            //Setting contractId only if element appears in Choose contract menu
            let activeContract = Date.parse(contract.endDate) > Date.now();
            if (contract.currentIntegrationsCount < contract.integrationsLimit  && activeContract) {
                setSelectedContract(contract);
                setShowSelectedContract(true);
            } else {
                setSelectedContract(null);
                setShowSelectedContract(false);
            }
        }
    }

    function getContractEdition(integrationsLimit) {
        if (integrationsLimit === 1) {
            return "SSO"
        } else if (integrationsLimit === 5) {
            return "Business"
        } else if (integrationsLimit >= 1000) {
            return "Enterprise"
        }
        return ""
    }

    let subscriptionData = [
        { label: "Edition", value: getContractEdition(contract ? contract.integrationsLimit : null) },
        { label: "Available integrations", value: contract ? (contract.currentIntegrationsCount + '/' + (contract.integrationsLimit === 100) ? "∞" : contract.integrationsLimit) : null},
        { label: "Identities purchased", value: contract ? ((contract.identitiesLimit === 1000000) ? "∞" : contract.identitiesLimit) : null},
        { label: "Start date", value: contract ? (contract.startDate ? contract.startDate.slice(0, 10) : null) : null},
        { label: "End date", value: contract ? (contract.endDate ? contract.endDate.slice(0, 10) : null) : null }
    ];

    if (!isVisible) return null;

    function ContractHeader({ contractNumber, contractLabelNeeded }) {
        return (
            <HeaderContainer>
                {contractLabelNeeded &&
                    <Typography component="span" sx={{ fontWeight: 400, fontSize: 18 }}>Contract ID:</Typography> }
                <Typography component="span" sx={{ fontWeight: 600, fontSize: 18 }}>{contractNumber}</Typography>
            </HeaderContainer>
        );
    }

    return (
        <StyledCard onClick={selectContract} contract={contract !== null ? contract : emptyContract} >
            {titleNeeded &&
                <ContractHeader contractNumber={contract !== null ? (contract.caption ? contract.caption : contract.contractId) : null} contractLabelNeeded={contractLabelNeeded}></ContractHeader>}
            {subscriptionData.map((item, index) => (
                <InfoBlock
                    key={`subscription-info-${index}`}
                    label={item.label}
                    value={item.value}
                />
            ))}
            {closeIconNeeded && <img onClick={handleStatusClick} id={"closeIcon"} src={closeIcon}></img>}
        </StyledCard>
    );
}

const StyledCard = styled(Box)(({ contract }) =>({
    opacity: contract.currentIntegrationsCount >= contract.integrationsLimit || Date.parse(contract.endDate) < Date.now() ? 0.5 : 1,
    display: "flex",
    gap: "8px",
    padding: "8px",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    backgroundColor: "var(--Gray-1, #f7f7f7)",
    letterSpacing: "0.14px",
    justifyContent: "space-around",
    flexWrap: "wrap",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    lineHeight: 1,
    width: '100%',
    marginTop: 6
}));

const HeaderContainer = styled('div')({
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    gap: "8px",
    fontSize: "18px",
    color: "var(--Black, #000)",
    lineHeight: 1,
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginLeft: 10,
    "& > *:last-child": {
        flex: 1,
        flexBasis: "0%"
    },
    "@media (max-width: 991px)": {
        maxWidth: "100%"
    }
});
