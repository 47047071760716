import * as React from 'react';
import BasicLayout from "../BasicLayout";
import FullScreenStepper from "../../components/FullScreenStepper";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { log, isValidURL, strip, availableServiceProviders, idPCertificateName, uploadFileUtils, addNewClientWithDomainUtils, generateAndAddUtils, saveUtils, saveDataOidcUtils, isValidDomain, addNewTeamviewerClientUtils, lowerAndTrimEmail, idPMetadataName } from "../../helpers/utils";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import { Button, SPIconName, ErrorSpan } from '../../components/CustomUI';
import Section from '../../components/Section';
import Configurator from '../../components/Configurator';
import TextFieldCopy from '../../components/TextFieldCopy';
import Api from "../../helpers/service";
import DownloadComponent from '../../components/DownloadComponent';
import { context, ACTION_TYPE } from "../../State";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from "@mui/styles"
import MethodChooseOidc from '../../components/MethodChooseOidc';
import CustomSnackbar from "../../components/Snackbar";
import errorIcon from "../../img/error-icon.svg";
import { isValidBase64 } from '../../helpers/utils';
import { isValidUUID } from '../../helpers/utils';
import SIEMSection from '../../components/SIEMSection';
import GrantAccessButtonToggle from '../../components/GrantAccessButtonToggle';
import GraphApi from '../../helpers/graph';
import { federateDomainTokenRequest } from '../../config/authConfig';
import arrowIcon from '../../img/arrow.svg'
import FederationOptionRow from '../../components/FederationOptionRow';
import StatusText from '../../components/StatusComponent';
import FederationComponent from '../../components/FederationComponent';
import {ContractSection} from "../../components/ContractSection";


const StyledIconButton = styled(IconButton)({
    "&:hover": {
        backgroundColor: "white"
    },
    "&:active": {
        backgroundColor: "white"
    }
});


const ServiceProviderIconButton = styled(StyledIconButton)({
    width: "100px",
    boxShadow: "0 0 20px #00000029",
    borderRadius: "9px",
    padding: "8px",
    marginRight: "32px",
    marginBottom: "32px"
});

const OutlinedInput = styled(MuiOutlinedInput)({
    border: 'none !important',
    background: '#F4F4F4 0% 0% no-repeat padding-box'
});

var currentServiceProvider = availableServiceProviders[0];
var integrationName = "";
var fileUploaded = null;
var domain = "";
var url = "";
var activeTab = 0;
var customerIdentifier = "";
var values = {
    "workspaceId": "",
    "primaryKey": "",
    "serverHostname": "",
    "serverPort": 0
};
var errors = {
    "workspaceId": false,
    "primaryKey": false,
    "serverHostname": false,
    "serverPort": false
};
var selectedSiem = "SENTINEL";

//integration constants
let MICROSOFT = "Microsoft";
let OKTA = "Okta";
let SALESFORCE = "Salesforce";
let AWS = "AWS";
let GSUITE = "Google Workspace";
let ZENDESK = "Zendesk";
let TEAMVIEWER = "Teamviewer";
let CUSTOM = "Custom";
let OIDC = "Oidc";
let KEEPER = "Keeper"

function ChooseIntegration(props) {

    const { t } = useTranslation();
    const { dispatch } = React.useContext(context);


    const handleClickOnSP = (serviceProvider, event) => {
        event.preventDefault();
        currentServiceProvider = serviceProvider;
        integrationName = "";
        fileUploaded = null;
        domain = "";
        let setMethod = "authn";
        let setFallbackMethod = undefined;
        dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });
        props.callback();
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ height: '700px', width: '1000px' }}>
            <Grid item xs alignItems="left">
                <Typography variant="integrationAddHeading" component="div" gutterBottom>
                    {t('add_integration.heading')}
                </Typography>
                <Typography variant="body2" component="div" gutterBottom style={{ maxWidth: '370px' }}>
                    {t('add_integration.select_application')}
                </Typography>
                <Grid item xs style={{ maxWidth: '708px', marginTop: '50px' }}>
                    {availableServiceProviders.map((sp, index) => {
                        if (sp.custom === null) {
                            return <ServiceProviderIconButton id={sp.elementId + "-img"} key={index} onClick={(event) => { handleClickOnSP(sp, event) }}>
                                <img id={sp.elementId + "-button"} src={sp.icon} title={sp.title} alt={sp.title} style={{ width: '96px', height: '96px' }} />
                            </ServiceProviderIconButton>

                        }
                        if (sp.custom === "SAML") {
                            return <ServiceProviderIconButton id={sp.elementId + "-img"} key={index} onClick={(event) => { handleClickOnSP(sp, event) }}>
                                <div id={sp.elementId + "-button"} style={{ textAlign: 'center' }}>
                                    <AddIcon style={{
                                        transform: 'translateY(70%)',
                                        font: 'normal normal bold 40px/21px Source Sans Pro',
                                        color: 'black',
                                        margin: 'auto',
                                        width: '30px',
                                        height: '30px'
                                    }} />
                                    <br />
                                    <div style={{
                                        textAlign: 'center',
                                        color: '#152035',
                                        opacity: 1,
                                        font: 'normal normal normal 14px/21px Source Sans Pro',
                                        marginTop: '20px',
                                        marginBottom: '10px'
                                    }}>{t('add_integration.saml_app')}</div>
                                </div>
                            </ServiceProviderIconButton>
                        }


                        if (sp.custom === "OIDC") {
                            return <ServiceProviderIconButton id={sp.elementId + "-img"} key={index} onClick={(event) => { handleClickOnSP(sp, event) }}>
                                <div style={{ textAlign: 'center' }} id={sp.elementId + "-button"}>
                                    <AddIcon  style={{
                                        transform: 'translateY(70%)',
                                        font: 'normal normal bold 40px/21px Source Sans Pro',
                                        color: 'black',
                                        margin: 'auto',
                                        width: '30px',
                                        height: '30px'
                                    }}
                                    />
                                    <br />
                                    <div style={{
                                        textAlign: 'center',
                                        color: '#152035',
                                        opacity: 1,
                                        font: 'normal normal normal 14px/21px Source Sans Pro',
                                        marginTop: '20px',
                                        marginBottom: '10px'
                                    }}>{t('add_integration.oidc_app')}</div>
                                </div>
                            </ServiceProviderIconButton>
                        }

                        return <div />
                    })}
                </Grid>
            </Grid>
        </Box>
    );
}

function NameIntegration(props) {
    const { callback } = props;
    const { t } = useTranslation();
    const [name, setName] = React.useState("");

    const handleNameChange = (event) => {
        setName(event.target.value);
        integrationName = strip(event.target.value);
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ height: '700px', width: '1000px' }}>
            <Grid item xs alignItems="left">
                <div>
                    {currentServiceProvider.icon !== null && <SPIconName id={currentServiceProvider.elementId + "-icon"} src={currentServiceProvider.icon} />}
                    <Typography variant="integrationAddHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                        {t('add_integration.name_your_integration')}
                    </Typography>
                </div>
                <Grid item xs style={{ maxWidth: '600px', marginTop: '50px' }}>
                    <OutlinedInput
                        id="outlinedNameInput"
                        inputProps={{ maxLength: 100 }}
                        placeholder={t('add_integration.integration_name')}
                        value={integrationName}
                        style={{ background: "F4F4F4 0% 0% no-repeat padding-box", width: "600px" }}
                        onChange={(event) => handleNameChange(event)} />
                    <Button style={{ marginTop: '47px' }} disabled={integrationName === ""} onClick={callback}>{t('add_integration.continue')}</Button>
                </Grid>
            </Grid>
        </Box>
    )
}

function ContentParameters(props) {
    const { credentialsSetter, credentials, isLoadedCredentials, setIsLoadedCredentials } = props;
    const showTextFieldCopy = (infoDescription) => {
        log("Show text field copy...");
        log(infoDescription);
        return (infoDescription.type === "endpointsso" || infoDescription.type === "endpointssopost" || 
            infoDescription.type === "endpointslo" || infoDescription.type === "entityid" ||
            infoDescription.type === "fingerprint" || infoDescription.type === "metadataurl")|| infoDescription.type === "metadataurl_wsfed" || infoDescription.type === "clientid"
            || infoDescription.type === "clientsecret" || infoDescription.type === "endpointauthorization" || infoDescription.type === "endpointtoken" || infoDescription.type === "endpointjwks"
            || infoDescription.type === "configurationopenid" || infoDescription.type === "tvendpointsso" || infoDescription.type === "tvendpointslo";
    }

    const showDownloadComponent = (infoDescription) => {
        return infoDescription.type === "certificate" || infoDescription.type === "metadataurl_modified";
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
            <Grid item xs alignItems="left">
                {currentServiceProvider.idPParameters.map((parameter, index) => {
                    if (showTextFieldCopy(parameter)) {
                        log("Parameter show TextField....");
                        log(parameter);
                        return <TextFieldCopy
                            infoDescription={parameter}
                            isOidc={currentServiceProvider.custom}
                            isMicrosoft = {currentServiceProvider.integrationType === "MICROSOFT"}
                            credentialsSetter={credentialsSetter !== undefined ? credentialsSetter : undefined}
                            credentials={credentials}
                            isLoadedCredentials={isLoadedCredentials}
                            setIsLoadedCredentials={setIsLoadedCredentials}
                             />
                    }
                    if (showDownloadComponent(parameter)) {
                        return parameter.type === "metadataurl_modified" ? <DownloadComponent
                            infoDescription={parameter}
                            filePath="/app/downloadIdp"
                            fileName={idPMetadataName}
                            isLinkComponent={true} /> : <DownloadComponent
                            infoDescription={parameter}
                            filePath="/app/download"
                            fileName={idPCertificateName} />
                    }

                    return <div />
                })}
            </Grid>
        </Box>
    )
}

function ScimContentParameters(props) {
    const {isLoadedScimCredentials, setIsLoadedScimCredentials, scimCredentials, setScimCredentials, checked} = props;
    const showTextFieldCopy = (infoDescription) => {
        log("Show text field copy...");
        log(infoDescription);
        return (infoDescription.type === "scimendpointurl" && checked) || (infoDescription.type === "scimsecrettoken" && checked);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
            <Grid item xs alignItems="left">
                {currentServiceProvider.idPParameters.map((parameter, index) => {
                    if (showTextFieldCopy(parameter)) {
                        log("Parameter show TextField....");
                        log(parameter);
                        return <TextFieldCopy
                            infoDescription={parameter}
                            isOidc={currentServiceProvider.custom}
                            isMicrosoft = {currentServiceProvider.integrationType === "MICROSOFT"}
                            isLoadedScimCredentials = {isLoadedScimCredentials}
                            setIsLoadedScimCredentials = {setIsLoadedScimCredentials}
                            scimCredentials = {scimCredentials}
                            setScimCredentials = {setScimCredentials}
                        />
                    }
                    return <div />
                })}
            </Grid>
        </Box>
    )
}

const FileUploader = props => {
    const { disable, setErrorMethod, setErrorMessage, setErrorWithMessage, errorWithMessage} = props;
    const hiddenFileInput = React.useRef(null);
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const { t } = useTranslation();

    const handleClick = event => {
        hiddenFileInput.current.click();
    }
    const handleChange = event => {
        if (event.target.files[0] === undefined) {
            log("Undefined uploaded file");
            return;
        }
        setUploadedFile(event.target.files[0]);
        fileUploaded = event.target.files[0];
    }
    return (
        <>
            <div id="fileUploadInput">
                <Button id="fileUploadInputButton" onClick={handleClick}> {t('add_integration.upload_file_button')} </Button>
                <div style={{
                    display: 'inline-block',
                    marginLeft: '18px',
                    transform: 'translateY(-80%)',
                    font: 'normal normal normal 14px/24px Source Sans Pro',
                    color: "#A0A0A0"
                }}>{fileUploaded === null || fileUploaded === undefined ? t('add_integration.no_file_chosen') : fileUploaded.name}</div>
                <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} />
            </div>
        </>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    tabRoot: {
        "&:hover": {
            background: "F4F4F4 0% 0% no-repeat padding-box",
            boxShadow: "3px 3px 6px #00000014",
            color: 'black',
            opacity: 1,
            fontWeight: 600
        }
    },
    selectedTab: {
        background: "F4F4F4 0% 0% no-repeat padding-box",
        boxShadow: "3px 3px 6px #00000014",
        color: 'black',
        opacity: 1,
        fontWeight: 600
    }
}));

function BasicTabs(props) {
    const [value, setValue] = React.useState(activeTab);
    const { error, setErrorMethod, disable, errorMessage, setErrorMessage, setErrorWithMessage, errorWithMessage } = props;
    const [enteredUrl, setEnteredUrl] = React.useState('');
    const { t } = useTranslation();
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        activeTab = newValue;
    };

    const handleUrlChange = event => {
        setEnteredUrl(event.target.value);
        url = event.target.value;
    }

    return (
        <Box id="basicTabs" sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 'none' }}>
                <Tabs forceRenderTabPanel={true} onChange={handleChange} style={{ background: "#FAFAFA 0% 0% no-repeat padding-box" }} TabIndicatorProps={{ style: { background: 'none' } }}>
                    <Tab label={t('add_integration.upload_metadata_tab_heading')} classes={{ root: classes.tabRoot, selected: classes.selectedTab }} style={{ color: 'black' }} />
                    <Tab label={t('add_integration.enter_metadata_tab_heading')} classes={{ root: classes.tabRoot, selected: classes.selectedTab }} style={{ color: 'black' }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{ background: '#F4F4F4 0% 0% no-repeat padding-box', height: '100px' }}>
                <FileUploader setErrorMethod={setErrorMethod} setErrorWithMessage={setErrorWithMessage} errorWithMessage={errorWithMessage} style={{ transform: 'translateY(50%)' }} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ background: '#F4F4F4 0% 0% no-repeat padding-box', height: '100px' }}>
                <OutlinedInput id="urlInput" value={url} defaultValue={url} placeholder={t('add_integration.enter_metadata')} style={{ background: "white 0% 0% no-repeat padding-box", width: "600px" }} onChange={(event) => handleUrlChange(event)} />
            </TabPanel>
        </Box>
    );
}
const MetadataSection = (props) => {
    const { t } = useTranslation();
    const { error, setErrorMethod, disable, errorMessage, setErrorMessage, setErrorWithMessage, errorWithMessage, domainRef, domainForFederation } = props;
    const [enteredDomain, setEnteredDomain] = React.useState('');
    const [enteredCustomerIdentifier, setEnteredCustomerIdentifier] = React.useState('');
    const [uri, setUri] = React.useState('');
    const [timer, setTimer] = React.useState(null);

    const { dispatch } = React.useContext(context);

    const showMetadataUpload = () => {
        return currentServiceProvider.formValue === SALESFORCE || currentServiceProvider.formValue === AWS || currentServiceProvider.formValue === OKTA || currentServiceProvider.formValue === KEEPER;
    }

    const showDomainInput = () => {
        return (currentServiceProvider.formValue === MICROSOFT || currentServiceProvider.formValue === GSUITE || currentServiceProvider.formValue === ZENDESK);
    }

    const showCustomSaml = () => {
        return currentServiceProvider.custom === "SAML";
    }

    const showCustomOidc = () => {
        return currentServiceProvider.custom === "OIDC";
    }

    const showCustomerIdentifier = () => {
        return currentServiceProvider.formValue === TEAMVIEWER;
    }

    const handleDomainChange = event => {
        domain = lowerAndTrimEmail(event.target.value);
        setEnteredDomain(event.target.value);
        domainForFederation.current = domain;        
    }

    const hadleCustomerIdentifierChange = event => {
        customerIdentifier = event.target.value;
        setEnteredCustomerIdentifier(event.target.value);

        clearTimeout(timer)
        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (enteredCustomerIdentifier !== "" && showCustomerIdentifier()) {
                setErrorWithMessage(false, "");
            }
        }, 500)

        setTimer(newTimer)
    }

    const handleUriChange = event => {
        setUri(event.target.value);
        url = event.target.value;
    }

    if (isValidURL(uri) && showCustomOidc()) {
        setErrorWithMessage(false, "");
    }

    return (
        <div>
            {showMetadataUpload() &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
                    <Grid item xs alignItems="left">
                        <div>
                            <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                                {t('add_integration.upload_metadata_subheading').replace("%s", currentServiceProvider.title)}
                            </Typography>
                        </div>
                        <Grid item xs style={{ maxWidth: '600px', marginTop: '20px' }}>
                            <FileUploader setErrorMethod={setErrorMethod} disable={disable} setErrorMessage={setErrorMessage} />
                        </Grid>
                        {error &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: '-25px' }}>
                                <img src={errorIcon} alt={"error"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                    </Grid>
                </Box>
            }
            {showDomainInput() &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
                    <Grid item xs alignItems="left">
                        <div>
                            {(currentServiceProvider.formValue === GSUITE || currentServiceProvider.formValue === MICROSOFT) &&
                                <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                                    {t('add_integration.enter_domain_subheading')}
                                </Typography>
                            }
                            {currentServiceProvider.formValue === ZENDESK &&
                                <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                                    {t('add_integration.enter_subdomain_subheading')}
                                </Typography>
                            }
                        </div>
                        <div>
                            <OutlinedInput id="domainInput" inputRef={domainRef} style={{ background: "F2F2F2 0% 0% no-repeat padding-box", width: "100%" }} value={domain} onChange={(event) => handleDomainChange(event)} />
                        </div>
                        {error &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                    </Grid>
                </Box>
            }

            {showCustomerIdentifier() &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
                    <Grid item xs alignItems="left">
                        <div>
                            {(currentServiceProvider.formValue === TEAMVIEWER) &&
                                <Typography variant="textFieldCopyHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                                    {t('add_integration.enter_customer_identifier_subheading')}
                                </Typography>
                            }
                        </div>
                        <div>
                            <OutlinedInput id="customIdentifierInput" style={{ background: "F2F2F2 0% 0% no-repeat padding-box", width: "100%" }} value={customerIdentifier} onChange={(event) => hadleCustomerIdentifierChange(event)} />
                        </div>
                        {error &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10, width: "100%" }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                    </Grid>
                </Box>
            }

            {showCustomSaml() &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
                    <Grid item xs alignItems="left">
                        <BasicTabs setErrorMethod={setErrorMethod} error={error} disable={disable} errorMessage={errorMessage} setErrorMessage={setErrorMessage} setErrorWithMessage={setErrorWithMessage} errorWithMessage={errorWithMessage} />
                        {error &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10 }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                    </Grid>
                </Box>
            }

            {showCustomOidc() &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ width: '1000px', marginBottom: '10px' }}>
                    <Grid item xs alignItems="left">
                        <div>
                            <OutlinedInput id="urlInput" value={url} style={{ background: "F2F2F2 0% 0% no-repeat padding-box", width: "100%" }} onChange={(event) => handleUriChange(event)} />
                        </div>
                        {error &&
                            <div style={{ fontSize: '10px', marginLeft: -5, marginTop: 10 }}>
                                <img src={errorIcon} alt={"err"} />
                                <ErrorSpan>{errorMessage}</ErrorSpan>
                            </div>
                        }
                    </Grid>
                </Box>
            }

        </div>
    )
}

function AddIntegration(props) {
    
    const { t } = useTranslation();
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [credentials, setCredentials] = React.useState(null);
    const [isLoadedCredentials, setIsLoadedCredentials] = React.useState(false);
    const [scimCredentials, setScimCredentials] = React.useState(null);
    const [isLoadedScimCredentials, setIsLoadedScimCredentials] = React.useState(false);
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    const [showSnackAddedIntegration, setShowSnackAddedIntegration] = React.useState(false);
    const [oidcConfiguration, setOidcConfiguration] = React.useState({ signingAlg: "RS256", encryptionAlg: "none", encryptionMeth: "none", authenticationMeth: "client_secret_post" });
    const [checkedScim, setCheckedScim] = React.useState(false);
    const [checkedSiem, setCheckedSiem] = React.useState(false);
    const [selectedSiem, setSelectedSiem] = React.useState("SENTINEL");
    const [siemCredentials, setSiemCredentials] = React.useState(true);
    const { state, dispatch } = React.useContext(context);
    const [showGrantAccessButton, setShowGrantAccessButton] = React.useState(true);
    const [adminEmail, setAdminEmail] = React.useState("");
    const [disabledManagedBtn, setDisabledManagedBtn] = React.useState(true);
    const [disabledFederateBtn, setDisabledFederateBtn] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [newContractId, setNewContractId] = React.useState(null);
    const [identitiesLimit, setIdentitiesLimit] = React.useState(1000000);
    const [integrationsLimit, setIntegrationsLimit] = React.useState(1000);
    const [contractReference, setContractReference] = React.useState(null);
    const [selectedContract, setSelectedContract] = React.useState(null);
    const [assignContractChecked, setAssignContractChecked] = React.useState(false);
    const domainRef = React.useRef(null);
    const domainForFederation = React.useRef('');
    const api = new Api();
    const graphApi = new GraphApi();
    graphApi.init(state.graphApiAccessToken);
    

    function goToLink() {
        window.open(currentServiceProvider.url, "_blank");
    }

    function isDomain() {
        return (currentServiceProvider.formValue === MICROSOFT || currentServiceProvider.formValue === GSUITE);
    }

    function onSuccess(res) {
        log(res);
        url = "";
        fileUploaded = null;
        var authConfig = {
            WEBAUTHN_ENABLED: true,
            WEBAUTHN_MD_ENABLED: true,
            AUTHN_ENABLED: true,
            CMLA_ENABLED: false,
            WEBAUTHN_ACCESS_KEY_ENABLED: false
        };
        dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: authConfig });
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_ADDED_SNACK, payload: true });
        window.location.assign("#/integrations");
    }

    function setErrorWithMessage(isError, message) {
        setError(isError);
        setErrorMessage(message);
    }

    function onFailure(error) {
        log(error.response.data.errorType);
        log(error.response.data.errorMessage);
        let errorMessage = "Error occurred";
        var isError = true;
        switch (error.response.data.errorType) {
            case "METADATA_NOT_VALID":
                errorMessage = t('add_integration.errors.metadata_not_valid') + ":" + error.response.data.errorMessage;
                break;
            case "CERTIFICATE_NOT_VALID":
                errorMessage = t('add_integration.errors.certificate_not_valid');
                break;
            case "REACHED_MAX_INTEGRATION_NUMBER":
                isError = false;
                dispatch({ type: ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK, payload: true });
                break;
            case "NOT_UNIQUE_ENTITY_ID":
                errorMessage = t('add_integration.errors.entity_id_not_unique');
                break;
            case "CERTIFICATE_EXPIRED":
                errorMessage = t('add_integration.errors.certificate_expired');
                break;
            case "CERTIFICATE_NOT_EXISTS":
                errorMessage = t('add_integration.errors.certificate_not_exists');
                break;
            case "DOMAIN_ALREADY_TAKEN":
                errorMessage = t('add_integration.errors.domain_taken');
                break;
            case "REACHED_MAX_INTEGRATION_NUMBER":
                isError = false;
                dispatch({ type: ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK, payload: true });
                break;
            default:
                window.location.assign("#/integrations");
                break;
        }
        if (isError) {
            setErrorWithMessage(isError, errorMessage);
        }

    }

    function validateSiemCredentials() {
        var primaryKeyValid = true;
        var workspaceIdValid = true;
        var serverPortValid = true;
        var serverHostnameValid = true;

        if((!isValidBase64(values["primaryKey"]) || values["primaryKey"] === "") && checkedSiem && selectedSiem === "SENTINEL") {
            primaryKeyValid = false;

        }

        if((!isValidUUID(values["workspaceId"]) || values["workspaceId"] === "") && checkedSiem && selectedSiem === "SENTINEL" ) {
            workspaceIdValid = false;
        }

        if(!isValidDomain(values["serverHostname"]) && checkedSiem && selectedSiem === "SYSLOG_QRADAR") {
            serverHostnameValid = false;
        }

        if(isNaN(values["serverPort"]) || Number(values["serverPort"]) < 1 || Number(values["serverPort"]) > 65536 && checkedSiem && selectedSiem === "SYSLOG_QRADAR") {
            serverPortValid = false;
        }

        if ((!primaryKeyValid || !workspaceIdValid) && selectedSiem === "SENTINEL") {
            return false;
        }

        if((!serverHostnameValid || !serverPortValid) && selectedSiem === "SYSLOG_QRADAR") {
            return false;
        }

        return true;
    } 

    function uploadFile() {
        if (fileUploaded === null) {
            setErrorWithMessage(true, t('add_integration.errors.metadata_not_valid'));
            if (!validateSiemCredentials()) {
                return;
            }
        } else {
            if (!validateSiemCredentials()) {
                return;
            }
            uploadFileUtils(api, fileUploaded, integrationName, currentServiceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, false, -1, checkedScim, checkedScim ? scimCredentials.scimClientId : "", checkedScim ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
        }
    }

    function addNewClientWithDomain() {
        if (domain === "" || !isValidDomain(domain)) {
            //not valid domain
            setErrorWithMessage(true, t('add_integration.errors.not_valid_domain'));
            if (!validateSiemCredentials()) {
                return;
            }
        } else {
            if (!validateSiemCredentials()) {
                return;
            }
            addNewClientWithDomainUtils(api, domain, integrationName, currentServiceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, false, state.integrationData.integrationId , checkedScim, checkedScim ? scimCredentials.scimClientId : "", checkedScim ?  scimCredentials.scimSecretToken : "", checkedSiem , selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference)
        }
    }

    function addNewTeamviewerClient() {
        if (customerIdentifier === "") {
            setErrorWithMessage(true, t('add_integration.errors.not_valid_customer_identifier'));
            if (!validateSiemCredentials()) {
                return;
            }
        } else {
            if (!validateSiemCredentials()) {
                return;
            }
            addNewTeamviewerClientUtils(api, customerIdentifier, credentials.clientId, integrationName, currentServiceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, false, state.integrationData.integrationId, checkedScim, checkedScim ? scimCredentials.scimClientId : "", checkedScim ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference)
        }
    }

    function generateAndAdd() {
        if (domain === "") {
            //not valid domain
            setErrorWithMessage(true, t('add_integration.errors.not_valid_domain'));
            if (!validateSiemCredentials()) {
                return;
            }
        } else {
            if (!validateSiemCredentials()) {
                return;
            }
            generateAndAddUtils(api, domain, integrationName, currentServiceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, false, -1, checkedScim, checkedScim ? scimCredentials.scimClientId : "", checkedScim ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference)
        }
    }

    function addCustomIntegration() {
        if (activeTab == 1) {
            if (!validateSiemCredentials()) {
                return;
            }
            saveUtils(api, url, integrationName, currentServiceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED,  state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, false, -1, checkedScim, checkedScim ? scimCredentials.scimClientId : "", checkedScim ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference)
        } else {
            uploadFile(); 
        }
    }
    
    const handleChangeScim = (event) => {
        setCheckedScim(event.target.checked)
    };

    const handleChangeSiem = (event) => {
        setCheckedSiem(event.target.checked)
    };

    function saveAlgorithmsandURL() {
        saveDataOidcUtils(api, credentials.clientId, url,
            oidcConfiguration.signingAlg, oidcConfiguration.encryptionAlg, oidcConfiguration.encryptionMeth, oidcConfiguration.authenticationMeth,
            credentials.clientSecret, integrationName,
            state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, false, -1, checkedScim, checkedScim ? scimCredentials.scimClientId : "", checkedScim ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function saveChanges() {
        if (currentServiceProvider.custom === "OIDC") {
            if (url === "" || !isValidURL(url)) {
                setErrorWithMessage(true, t('add_integration.errors.invalid_url'));
                if (!validateSiemCredentials()) {
                return;
            }
            } else {
                if (!validateSiemCredentials()) {
                return;
            }
                saveAlgorithmsandURL();
            }  
        } else { 
            switch (currentServiceProvider.formValue) {
                case OKTA:
                case SALESFORCE:
                case AWS:
                case KEEPER:
                    uploadFile();
                    break;
                case MICROSOFT:
                case GSUITE:
                    addNewClientWithDomain();
                    break;
                case TEAMVIEWER:
                    addNewTeamviewerClient();
                    break;
                case ZENDESK:
                    generateAndAdd();
                    break;
                case CUSTOM:
                    addCustomIntegration();
                    break;
                default:
                    break;
            }
        }
    }

    const handleSnackReachedMaxIntegrationError = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK, payload: false });
    }
    var protocol = currentServiceProvider.integrationType === "OIDC" ? "OIDC" : (currentServiceProvider.integrationType === "MICROSOFT" ? "WS-Fed" : "SAML");
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ height: '700px', width: '1000px' }}>
            <Grid item xs alignItems="left">
                <div>
                    {currentServiceProvider.icon !== null && <SPIconName id={currentServiceProvider.elementId + "-icon"} src={currentServiceProvider.icon} />}
                    <Typography variant="integrationAddHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                        <div>
                            {t('add_integration.configure_sp')}
                            <span style={{ color: '#A2A2A2', fontWeight: 400, marginLeft: 5 }} >{integrationName}</span>
                        </div>
                    </Typography>
                </div>
                <Grid item xs style={{ maxWidth: '900px', marginTop: '100px' }}>
                    {currentServiceProvider.custom === null &&
                        <div>
                            <Section heading={t('add_integration.follow_guide')} content={<Button onClick={goToLink}>{t('add_integration.open_guide')}</Button>} />
                        </div>
                    }
                    <div style={{ marginTop: "82px", width: "110%" }}>
                        <Section heading={currentServiceProvider.custom !== 'OIDC' ? t('add_integration.idp_parameters_heading') : t('add_integration.oidc_settings')} content={<ContentParameters credentialsSetter={setCredentials} credentials={credentials} isLoadedCredentials={isLoadedCredentials} setIsLoadedCredentials={setIsLoadedCredentials} />} />
                    </div>

                    <div style={{ marginTop: "82px", width: "110%" }}>
                        <Section heading={currentServiceProvider.custom !== 'OIDC' ? t('add_integration.metadata_heading') : t('add_integration.redirect_uri')} isErrorOccurred={error} content={<MetadataSection setErrorMethod={setError} error={error} disable={setButtonDisabled} errorMessage={errorMessage} setErrorMessage={setErrorMessage} setErrorWithMessage={setErrorWithMessage} errorWithMessage={error} domainRef={domainRef} domainForFederation={domainForFederation} />} />
                    </div>

                    {currentServiceProvider.title === "Microsoft" &&
                        <div style={{ marginTop: "82px", position:'relative' }}>
                            <Section heading={t('federate_domain.section_heading')} content={<FederationComponent domainInputRef={domainRef} showGrantAccessButton={showGrantAccessButton} setShowGrantAccessButton={setShowGrantAccessButton} adminEmail={adminEmail} setAdminEmail={setAdminEmail} domain={domainForFederation} setDisabledFederateBtn={setDisabledFederateBtn} setDisabledManagedBtn={setDisabledManagedBtn} disabledFederateBtn={disabledFederateBtn} disabledManagedBtn={disabledManagedBtn} disabled={disabled} setDisabled={setDisabled} />}/>
                        </div>
                    }

                    <div style={{ marginTop: "82px" }}>
                        <Section heading={t('add_integration.configurator_heading')} content={<div>
                            <div style={{
                                textAlign: 'left',
                                font: 'normal normal normal 14px/18px Source Sans Pro',
                                letterSpacing: '0px',
                                color: "#000000",
                                opacity: 1,
                                marginTop: '-30px',
                                marginBottom: '30px'
                            }}>{t('add_integration.configurator_subheading')}</div>
                            <Configurator isDomainIntegration={isDomain() ? 'true' : 'false'} shouldShowAccessKey={!(protocol === "SAML")} />
                        </div>} width={'110%'} />
                    </div>

                    {currentServiceProvider.custom === "OIDC" &&
                        <div style={{ marginTop: "82px" }}>
                            <Section heading={"Optional step"} content={<MethodChooseOidc oidcConfiguration={oidcConfiguration} setOidcConfiguration={setOidcConfiguration} />} />
                        </div>
                    }
                    <div style={{ marginTop: "82px" }}>
                    <Section heading={"Assign contract"} content={<ContractSection  setNewContractId={setNewContractId}
                                                                                    identitiesLimit={identitiesLimit}
                                                                                    setIdentitiesLimit={setIdentitiesLimit}
                                                                                    integrationsLimit={integrationsLimit}
                                                                                    setIntegrationsLimit={setIntegrationsLimit}
                                                                                    contractReference={contractReference}
                                                                                    setContractReference={setContractReference}
                                                                                    selectedContract={selectedContract}
                                                                                    setSelectedContract={setSelectedContract}
                                                                                    assignContractChecked={assignContractChecked}
                                                                                    setAssignContractChecked={setAssignContractChecked}
                                                                                    contract={null}
                                                                                    isEdit={true}/>}/>
                    </div>

                    { currentServiceProvider.integrationType !== 'OIDC' &&
                        <div style={{ marginTop: "82px" }}>
                            <Section handleChange = {handleChangeScim} checked = {checkedScim} heading={t('add_integration.scim_provisioning')} content = {
                            <div style = {{whiteSpace:'pre-wrap'}}>
                                {t('integration_view.user_provisioning')}
                                <ScimContentParameters isLoadedScimCredentials={isLoadedScimCredentials} setIsLoadedScimCredentials = {setIsLoadedScimCredentials} scimCredentials = {scimCredentials} setScimCredentials = {setScimCredentials} checked = {checkedScim} />
                            </div> }
                            />
                        </div>
                    }

                    <div style={{ marginTop: "82px" }}>
                        <Section handleChange={handleChangeSiem} checked={checkedSiem} heading={t('add_integration.siem_heading')} isErrorOccurred={errors["primaryKey"] || errors["workspaceId"] || errors["serverPort"] || errors["serverHostname"]} content={
                            <SIEMSection
                                t={t}
                                inSidebar={false}
                                checked={checkedSiem}
                                selectedSiem={selectedSiem}
                                setSelectedSiem={setSelectedSiem}
                                values={values}
                                errors={errors}
                            />
                        } />
                    </div>

                    <div style={{ marginTop: "82px", width: "110%", textAlign: "end" }}>
                        <Button onClick={saveChanges}>{t('add_integration.save_changes_button_heading')}</Button>
                    </div>
                </Grid>

            </Grid>
            <CustomSnackbar open={state.showReachedMaxIntegrationErrorSnack} onCancel={handleSnackReachedMaxIntegrationError} type={'error'} vertical={'bottom'} horizontal={'right'} text={t('notification.info_reached_max')} buttonText={t('notification.okay')} showIcon='true' />
        </Box>
    )
}

function AddIntegrationPage(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const { dispatch } = React.useContext(context);


    function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        fileUploaded = null;
        url = "";
        domain = "";
        customerIdentifier = "";
        values["workspaceId"] = "";
        values["primaryKey"] = "";
        values["serverHostname"] = "";
        values["serverPort"] = 0;
        errors["workspaceId"] = false;
        errors["primaryKey"] = false;
        errors["serverHostname"] = false;
        errors["serverPort"] = false;
        dispatch({ type: ACTION_TYPE.METHOD, payload: { method: "authn", fallbackMethod: undefined } });
    }

    const steps = [<ChooseIntegration callback={handleNext} />, <NameIntegration callback={handleNext} />, <AddIntegration callback={handleNext} />];

    const getCurrentStepCallback = (step) => {
        setActiveStep(step);
    };

    return (
        <BasicLayout sx={{backgroundColor: "#FFFFFF"}} content={
            <FullScreenStepper steps={steps} gotoStep={activeStep} currentStep={getCurrentStepCallback} isAddIntegration={'true'} />
        } hasFooter={false} />
    );
}

export default withRouter(AddIntegrationPage);