import React, { useEffect } from 'react';
import { context, ACTION_TYPE } from "../State";
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
    serviceProvidersIcon,
    availableServiceProviders,
    getTextFieldValue,
    idPCertificateName,
    log,
    isValidDomain,
    MICROSOFT,
    OKTA,
    SALESFORCE,
    AWS,
    GSUITE,
    ZENDESK,
    CUSTOM,
    generateAndAddUtils,
    TEAMVIEWER,
    addNewTeamviewerClientUtils,
    KEEPER,
    idPMetadataName,
    isNumeric
} from '../helpers/utils.js';
import { uploadFileUtils, addNewClientWithDomainUtils, saveDataOidcUtils, saveUtils, strip } from '../helpers/utils.js';
import Typography from "@mui/material/Typography/Typography";
import { SPIconName, ErrorSpan } from "./CustomUI";
import MuiDivider from '@mui/material/Divider';
import { styled } from "@mui/material";
import { useTranslation } from 'react-i18next';
import IntegrationDetailsSection from './IntegrationDetailsSection';
import IntegrationDetailsSectionItem from './IntegrationDetailsSectionItem';
import DownloadComponent from './DownloadComponent';
import TextFieldCopy from './TextFieldCopy';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SelectItemList from "./SelectListMenu";
import Configurator from "./Configurator";
import editIcon from "../img/icon-edit.svg";
import deleteIcon from "../img/icon-delete.svg";
import { Button } from "./CustomUI"
import Api from "../helpers/service";
import errorIcon from "../img/error-icon.svg";
import {tryLocalStorageSetItem} from "../helpers/utils";
import { CustomCopyIcon } from './CustomUI';
import { configManager } from '../App';
import { CustomTooltip } from './CustomUI';
import { getTheme } from '../theme/theme';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SIEMSection from './SIEMSection';
import { isValidBase64 } from '../helpers/utils.js';
import { isValidUUID } from '../helpers/utils.js';
import FederationComponent from './FederationComponent';
import {ContractSection} from "./ContractSection";
import ContractRemovalConfirmationDialog from "./ContractRemovalConfirmationDialog";

export default function IntegrationView(props) {

    const { onClose, isEditModeOn } = props;
    const { state, dispatch } = React.useContext(context);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isShown, setIsShown] = React.useState(null);
    const [integrationData, setIntegrationData] = React.useState(state.integrationData);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [click, setClick] = React.useState(false);
    const [checked, setChecked] = React.useState(integrationData.scimEnabled);
    const [scimCredentials, setScimCredentials] = React.useState(null);
    const [isLoadedScimCredentials, setIsLoadedScimCredentials] = React.useState(false);
    const [selectedSiem, setSelectedSiem] = React.useState('SENTINEL');
    const [checkedSiem, setCheckedSiem] = React.useState(integrationData.siemEnabled);
    const [showGrantAccessButton, setShowGrantAccessButton] = React.useState(true);
    const [adminEmail, setAdminEmail] = React.useState("");
    const [disabledManagedBtn, setDisabledManagedBtn] = React.useState(true);
    const [disabledFederateBtn, setDisabledFederateBtn] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [newContractId, setNewContractId] = React.useState(null);
    const [identitiesLimit, setIdentitiesLimit] = React.useState(1000000);
    const [integrationsLimit, setIntegrationsLimit] = React.useState(5);
    const [contractReference, setContractReference] = React.useState({});
    const [selectedContract, setSelectedContract] = React.useState(null);
    const [assignContractChecked, setAssignContractChecked] = React.useState(integrationData.licenseType === "Licensed");
    const [showContractRemovalDialog, setShowContractRemovalDialog] = React.useState(false);
    const [removeContract, setRemoveContract] = React.useState(null);

    const { t } = useTranslation();

    const blue = getTheme().palette.primary.main;
    const black = getTheme().palette.common.black;
    const lightGray = getTheme().palette.onHover.inverse;

    let isEditMode = isEditModeOn === undefined ? false : isEditModeOn;
    //if Siem was enabled for integration before
    let isSiemEnabled = integrationData.siemEnabled;

    var values = {
        "workspaceId": "",
        "primaryKey": "",
        "serverHostname": "",
        "serverPort": 0
    };
    var errors = {};
    
    const api = new Api();

    //authentication constants
    const AUTHN = "authn";
    const WEBAUTHN = "webauthn";
    const CMLA = "cmla";

    const Divider = styled(MuiDivider)(({ theme }) => ({
        marginBottom: 22,
        width: '80%'
    }));

    const fetchSCIM = async () => {
        if (!isLoadedScimCredentials) {
            try {
                const { data } = await api.generateSCIMCredentials("/app/generateSCIMCredentials");
                setScimCredentials(data);
                setIsLoadedScimCredentials(true);
            } catch (err) {
                log(err);
            }
        }
    }

    useEffect(() => {
        setIntegrationData(state.integrationData);
        setChecked(state.integrationData.scimEnabled);
        setCheckedSiem(state.integrationData.siemEnabled);
        setAssignContractChecked(state.integrationData.licenseType === "Licensed");
        setSelectedContract(state.integrationData.contract !== null ? state.integrationData.contract : null);
        if(state.integrationData.siemType !== "" && state.integrationData.siemType !== null && state.integrationData.siemType !== undefined) {
            setSelectedSiem(state.integrationData.siemType);
        }
        if(state.integrationData.scimEnabled === false) {
            fetchSCIM();
        }
    }, [state?.integrationData]);

    const handleClick = () => {
        setClick(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };
    
    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(function(){
            setOpen(false);
            setClick(false);
        }, 1000);
        
    };
    
    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
    };

    const handleChangeSwitchSiem = (event) => {
        setCheckedSiem(event.target.checked);
    }

    const integrationType = integrationData.integrationType;
    
    let sp = availableServiceProviders.filter(serviceProvider => serviceProvider.integrationType === integrationType);

    var fileUploaded = null;
    let serverName = state.integrationData.serverName !== null  ? state.integrationData.serverName : configManager.serverName;
    var domainInviteLink = "https://" + serverName + "/saml/signup/" + (state.integrationData.integrationType === 'MICROSOFT' || state.integrationData.integrationType === 'GSUITE' ? state.integrationData.domain : state.integrationData.integrationId);

    const handleClose = (index, event) => {
        setIsShown(false);
    };
    //make json for IdP parameters
    let paramJSON = [];
    let isMetadataUrl = false;

    if (sp !== undefined && sp[0] !== undefined) {
        sp[0].idPParameters.map(param => {
            let labelKey = param['label'];
            let paramType = param['type'];
            let labelValue = '';

            if (paramType === "clientsecret" || paramType === "clientid" || paramType === "scimendpointurl" || paramType === "scimsecrettoken") {
                return null;
            }
            if (paramType === "tvendpointsso") {
                labelValue = "https://alpha-lb.saml.getidee.de/idp/idee/"+ state.integrationData.clientId +"/SSO/Redirect"
            } else if (paramType === "tvendpointslo") {
                labelValue = "https://alpha-lb.saml.getidee.de/idp/idee/"+ state.integrationData.clientId +"/SLO/Redirect"
            } else if (paramType === "metadataurl_wsfed" && state.integrationData.serverName !== null) {
                labelValue = "https://" + state.integrationData.serverName +"/wsfed/idp/metadata"
            } else if (paramType === "metadataurl" && state.integrationData.serverName != null) {
                labelValue = "https://" + state.integrationData.serverName + "/idp/metadata"
            } else if (paramType === "metadataurl_modified") {
                labelValue = "";
                isMetadataUrl = true;
            } else {
                labelValue = getTextFieldValue(param['type']);
            }
            
            paramJSON.push({
                [labelKey]: [labelValue],
                'type': [paramType]
            });
            return null;
        })
    }

    const errorDiv = <div style={{ fontSize: '10px', marginLeft: -5, marginTop: '-25px' }}>
        <img src={errorIcon} alt={"error"} />
        <ErrorSpan>{errorMessage}</ErrorSpan>
    </div>


    const icon =
        (!(integrationType === "CUSTOM")) ?
            <SPIconName style={{ width: 50, height: 50, marginRight: '9px' }} src={serviceProvidersIcon[integrationData.integrationType]} />
            : <AddIcon style={{
                font: 'normal normal bold 40px/21px Source Sans Pro',
                color: 'black',
                width: '50px',
                height: '50px',
                marginRight: '10px'
            }} />;

    const iconAndDescription =
        (!(integrationType === "CUSTOM")) ?
            <span><SPIconName style={{ width: 40, height: 40, marginRight: '9px' }} src={serviceProvidersIcon[integrationData.integrationType]} /> {integrationData.integrationType}</span>
            : <span><AddIcon style={{
                font: 'normal normal bold 40px/21px Source Sans Pro',
                color: 'black',
                transform: 'translateY(10px)',
                width: '40px',
                height: '40px',
                marginRight: '10px'
            }} />CUSTOM</span>;

    let rowActionMenuItems = [
        { text: t('select_menu.edit'), imageUrl: editIcon, isDisabled: state.integrationData.isShared ? "true" : "false", type: "integration", func: handleOpenDialogEditIntegration },
        { text: t('select_menu.delete_integration'), imageUrl: deleteIcon, isDisabled: state.integrationData.isShared ? "true" : "false", type: "integration", func: handleOpenDialogDeleteIntegration }
    ];

    function handleOpenDialogDeleteIntegration(index, event) {
        setIsShown({ [index]: false });
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: true });
    }

    function handleOpenDialogEditIntegration(index, event) {
        handleClose();
        dispatch({ type: ACTION_TYPE.OPEN_VIEW_DETAILS_INTEGRATION, payload: false });
        dispatch({ type: ACTION_TYPE.OPEN_EDIT_INTEGRATION, payload: true });
    }
    

    function handleTextFieldChange(type, e) {
        if (type !== undefined) {
            let integrationDataNew = integrationData;
            switch (type) {
                case "domain":
                    integrationDataNew.domain = e.target.value;
                    break;
                case "name":
                    integrationDataNew.integrationName = strip(e.target.value);
                    break;
                case "url":
                    integrationDataNew.metadataUrl = e.target.value;
                    break;
                default:
                    break;
            }

            setIntegrationData(integrationDataNew);
        }
    }

    function onSuccess(res) {
        log(res);
        fileUploaded = null;
        var authConfig = {
            WEBAUTHN_ENABLED: true,
            WEBAUTHN_MD_ENABLED: true,
            WEBAUTHN_ACCESS_KEY_ENABLED: false,
            AUTHN_ENABLED: true,
            CMLA_ENABLED: false
        };
       
        dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: authConfig });
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, true);
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, payload: true });
        window.location.reload();
    }

    function setErrorWithMessage(isError, message) {
        setError(isError);
        setErrorMessage(message);
    }

    function onFailure(error) {
        log(error);
        log(error.response.data.errorType);
        log(error.response.data.errorMessage);
        let errorMessage = "Error occurred";
        var isError = true;
        switch (error.response.data.errorType) {
            case "METADATA_NOT_VALID":
                errorMessage = t('add_integration.errors.metadata_not_valid') + ":" + error.response.data.errorMessage;
                break;
            case "CERTIFICATE_NOT_VALID":
                errorMessage = t('add_integration.errors.certificate_not_valid');
                break;
            case "REACHED_MAX_INTEGRATION_NUMBER":
                isError = false;
                dispatch({ type: ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK, payload: true });
                break;
            case "NOT_UNIQUE_ENTITY_ID":
                errorMessage = t('add_integration.errors.entity_id_not_unique');
                break;
            case "CERTIFICATE_EXPIRED":
                errorMessage = t('add_integration.errors.certificate_expired');
                break;
            case "CERTIFICATE_NOT_EXISTS":
                errorMessage = t('add_integration.errors.certificate_not_exists');
                break;
            case "DOMAIN_ALREADY_TAKEN":
                errorMessage = t('add_integration.errors.domain_taken');
                break;
            case "REACHED_MAX_INTEGRATION_NUMBER":
                isError = false;
                dispatch({ type: ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK, payload: true });
                break;
            default:
                window.location.assign("#/integrations");
                break;
        }
        if (isError) {
            setErrorWithMessage(isError, errorMessage);
        }

    }

    function uploadFile() {
        uploadFileUtils(api, fileUploaded, integrationData.integrationName, sp[0].formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, true, integrationData.integrationId,  checked, (scimCredentials !== null && checked) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checked) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values,selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function addNewClientWithDomain() {
        addNewClientWithDomainUtils(api, integrationData.domain, integrationData.integrationName, sp[0].formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, true, integrationData.integrationId, checked, (scimCredentials !== null && checked) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checked) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function addNewTeamviewerClient() {
        addNewTeamviewerClientUtils(api,null, integrationData.clientId, integrationData.integrationName, sp[0].formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, true, integrationData.integrationId, checked, (scimCredentials !== null && checked) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checked) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function generateAndAdd() {
        generateAndAddUtils(api, integrationData.domain, integrationData.integrationName, sp[0].formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, true, integrationData.integrationId, checked, (scimCredentials !== null && checked) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checked) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function addCustomIntegration() {
        if (integrationData.metadataUrl !== null) {
            saveUtils(api, integrationData.metadataUrl, integrationData.integrationName, sp[0].formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, true, integrationData.integrationId, checked, (scimCredentials !== null && checked) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checked) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference)
        } else {
            uploadFile();
        }
    }

    function saveAlgorithmsandURL() {
        saveDataOidcUtils(api, null, null, null, null, null, null,
            null, integrationData.integrationName,
            state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, true, integrationData.integrationId,  checked, (scimCredentials !== null && checked) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checked) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function saveChanges() {
        let custom = sp[0].custom;
        let formValue = sp[0].formValue;
        var primaryKeyValid = true;
        var workspaceIdValid = true;
        var serverHostnameValid = true;
        var serverPortValid = true;

        if(!isValidBase64(values["primaryKey"]) && checkedSiem && selectedSiem === "SENTINEL") {
            primaryKeyValid = false;

        } 
        if(!isValidUUID(values["workspaceId"]) && checkedSiem && selectedSiem === "SENTINEL" ) {
            workspaceIdValid = false;
        }
        if(!isValidDomain(values["serverHostname"]) && checkedSiem && selectedSiem === "SYSLOG_QRADAR" ) {
            serverHostnameValid = false;
        }

        if(isNaN(values["serverPort"]) || Number(values["serverPort"]) < 1 || Number(values["serverPort"]) > 65536 && checkedSiem && selectedSiem === "SYSLOG_QRADAR" ) {
            serverPortValid = false;
        }

        if (isSiemEnabled && (values["primaryKey"] === "" || values["workspaceId"] === "" || values["serverHostname"] === "" || values["serverPort"] === 0)) {
            //this is in case that no value in SIEM section is changed
            primaryKeyValid = true;
            workspaceIdValid = true;
            serverHostnameValid = true;
            serverPortValid = true;
        }

        if ((!primaryKeyValid || !workspaceIdValid) && selectedSiem === "SENTINEL") {
            return false;
        }

        if((!serverHostnameValid || !serverPortValid) && selectedSiem === "SYSLOG_QRADAR") {
            return false;
        }

        for (let key in contractReference) {
            if (!isNumeric(contractReference[key])) {
                return;
            }
        }
        
        if(integrationData.integrationName === "") {
            //if integration name is empty or HTML code, saving changes is not possible 
            return;
        }

        if (integrationData.licenseType === "Licensed" && newContractId === null && selectedContract === null) {
            if (removeContract === null || removeContract === false) {
                setShowContractRemovalDialog(true);
                return;
            }
        }
        
        if (custom === "OIDC") {
            saveAlgorithmsandURL();
        } else {
            switch (formValue) {
                case OKTA:
                case SALESFORCE:
                case AWS:
                case KEEPER:
                    uploadFile();
                    break;
                case MICROSOFT:
                case GSUITE:
                    addNewClientWithDomain();
                    break;
                case TEAMVIEWER: 
                    addNewTeamviewerClient();
                    break;
                case ZENDESK:
                    generateAndAdd();
                    break;
                case CUSTOM:
                    addCustomIntegration();
                    break;
                default:
                    break;
            }
        }

    }

    const FileUploader = props => {
        const { disable, setErrorMethod, setErrorMessage } = props;
        const hiddenFileInput = React.useRef(null);
        const [uploadedFile, setUploadedFile] = React.useState(null);
        const { t } = useTranslation();

        const handleClick = event => {
            hiddenFileInput.current.click();
        }
        const handleChange = event => {
            if (event.target.files[0] === undefined) {
                log("Undefined uploaded file");
                return;
            }
            setUploadedFile(event.target.files[0]);
            fileUploaded = event.target.files[0];
        }
        return (
            <>
                <div>
                    <Button onClick={handleClick}> {t('add_integration.upload_file_button')} </Button>
                    <div style={{
                        display: 'inline-block',
                        marginLeft: '18px',
                        transform: 'translateY(-80%)',
                        font: 'normal normal normal 14px/24px Source Sans Pro',
                        color: "#A0A0A0"
                    }}>{fileUploaded === null || fileUploaded === undefined ? t('add_integration.no_file_chosen') : fileUploaded.name}</div>
                    <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} />
                </div>
            </>
        )
    }

    var metadata = "";
    var typeOfMetadata = "";
    var protocol = integrationType === "OIDC" ? "OIDC" : (integrationType === "MICROSOFT" ? "WS-Fed" : "SAML");
    switch (integrationType) {
        case "MICROSOFT":
        case "GSUITE":
            metadata = integrationData.domain;
            typeOfMetadata = "domain";
            break;
        case "CUSTOM":
            typeOfMetadata = integrationData.metadataUrl == null ? "file" : "url";
            metadata = typeOfMetadata === "file" ? (!isEditModeOn ? <div>
                <DownloadComponent
                    infoDescription={integrationData.integrationName + "_metadata.xml"}
                    filePath={"/app/download/metadata?id=" + integrationData.clientMetadataId}
                    fileName={integrationData.integrationName + "_metadata.xml"}
                    isLinkComponent={isEditModeOn ? false : true} />
            </div> : <div><FileUploader />{error &&
                <div style={{ fontSize: '10px', marginLeft: -5, marginTop: '-25px' }}>
                    <img src={errorIcon} alt={"error"} />
                    <ErrorSpan>{errorMessage}</ErrorSpan>
                </div>
            }</div>) : integrationData.metadataUrl
            break;
        case "OKTA":
        case "SALESFORCE":
        case "AWS":
        case "KEEPER": 
            typeOfMetadata = "file";
            metadata = !isEditModeOn ? <DownloadComponent
                infoDescription={integrationData.integrationName + "_metadata.xml"}
                filePath={"/app/download/metadata?id=" + integrationData.clientMetadataId}
                fileName={integrationData.integrationName + "_metadata.xml"}
                isLinkComponent={isEditModeOn ? false : true} /> :
                <div>
                    <FileUploader />
                    {error &&
                        <div style={{ fontSize: '10px', marginLeft: -5, marginTop: '-25px' }}>
                            <img src={errorIcon} alt={"error"} />
                            <ErrorSpan>{errorMessage}</ErrorSpan>
                        </div>
                    }
                </div>
            break;
        default:
            break;

    }

    //information as JSON about each section within view/edit window
    const infos = [
        {
            "Main Info": [{
                "Integration Protocol": protocol,
                "Application": iconAndDescription,
                "Name": integrationData.integrationName
            }],
            "isEditable": true,
            "isCopied": false,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible": true,
            "subheadingNeeded" : false,
        }, {

            [protocol + t('integration_view.sp_parameters')]: paramJSON,
            "isEditable": false,
            "isCopied": true,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible": true,
            "subheadingNeeded" : false
        }, {
            "Service provider metadata": [
                (typeOfMetadata === "file") && { [t('integration_view.saml_metadata')]: [metadata], "type": typeOfMetadata },
                (typeOfMetadata === "url") && { [t('integration_view.saml_metadata_url')]: [metadata], "type": typeOfMetadata },
                (typeOfMetadata === "domain") && { [t('integration_view.domain')]: [metadata], "type": typeOfMetadata }
            ],
            "isEditable": !(typeOfMetadata === "domain"),
            "isCopied": false,
            "isConfigurator": false,
            "isFileUpload": typeOfMetadata === "file" ? true : false,
            "isVisible": !(integrationType === "OIDC" || integrationType === "ZENDESK" || integrationType === "TEAMVIEWER"),
            "subheadingNeeded" : false,
        },{
            "Federate your domain now":[],
            "isEditable": false,
            "isCopied": false,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible": isEditMode && integrationType === "MICROSOFT",
            "subheadingNeeded" : false,
        },{
            "Authentication method": [{
                "Login method": integrationData.loginMethod,
                "Fallback Login method": integrationData.fallbackMethod,
                "Number of devices": integrationData.numberOfDevices
            }],
            "isEditable": true,
            "isCopied": false,
            "isConfigurator": true,
            "isFileUpload": false,
            "isVisible": true,
            "subheadingNeeded" : false,
        },{
            "Contract":[],
            "isEditable": false,
            "isCopied": false,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible": isEditMode || (state.integrationData.contract !== null ? state.integrationData.contract.contractId !== null : false),
            "subheadingNeeded" : false,
        }, {
            "Automatic User Provisioning \n and De-provisioning" : [
                {"SCIM Endpoint URL": state.integrationData.scimEnabled ? state.integrationData.scimEndpointUrl : (scimCredentials === null ? "" : "https://" + configManager.serverName + "/scim/v2/" + scimCredentials.scimClientId ), "type" : "scimendpointurl"},
                {"Secret": state.integrationData.scimEnabled ? state.integrationData.scimSecret : (scimCredentials === null ? "" : scimCredentials.scimSecretToken), "type" : "scimsecrettoken"}
            ],
            "isEditable": false,
            "isCopied": true,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible" : isEditMode && integrationType !== "OIDC",
            "subheadingNeeded" : true,

        }, {
            "Automatic User Provisioning\n and De-provisioning" : [
                {"SCIM Endpoint URL": state.integrationData.scimEnabled ? state.integrationData.scimEndpointUrl : (scimCredentials === null ? "" : "https://" + configManager.serverName + "/scim/v2/" + scimCredentials.scimClientId ), "type" : "scimsecrettoken"},
                {"Number of synced users:" : state.integrationData.numberOfSyncedUsers}
            ],
            "isEditable": false,
            "isCopied": true,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible" : ((isEditMode === false) && (state.integrationData.scimEnabled === true)) && integrationType !== "OIDC",
            "subheadingNeeded" : true,

        },{
            "SIEM" : [
                {"Workspace ID": state.integrationData.workspaceId},
            ],
            "isEditable": false,
            "isCopied": false,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible" : !isEditModeOn && (state.integrationData.siemEnabled === true) && state.integrationData.siemType === "SENTINEL",
            "subheadingNeeded" : false,

        },{
            "SIEM" : [
                {[t('add_integration.siem_server_hostname')]: state.integrationData.serverHostname},
                {[t('add_integration.siem_server_port_number')]: state.integrationData.serverPort}
            ],
            "isEditable": false,
            "isCopied": false,
            "isConfigurator": false,
            "isFileUpload": false,
            "isVisible" : !isEditModeOn && (state.integrationData.siemEnabled === true) && state.integrationData.siemType === "SYSLOG_QRADAR",
            "subheadingNeeded" : false,

        }
    ]

    let today = new Date().getTime();
    let expirationDayinMilliseconds = state.integrationData.expirationDate;
    let milliseconds = expirationDayinMilliseconds - today;
    let millsecInDay = 86400000;
    let trialText =  milliseconds <= 0 ? " expired " : Math.round(milliseconds/millsecInDay)+ " days left";
    //span with trialText is place for info component about talking to sales


    return (
        <>
            <Drawer
                anchor={'right'}
                open={isEditModeOn === true ? state.isOpenedDialogEditIntegration : state.isOpenedDialogViewDetailsIntegration}
                onClose={(_, reason) =>
                    reason !== 'backdropClick' && onClose()
                }
                PaperProps={{
                    sx: { width: "717px" }
                }}
            >
                <div>
                    <IconButton onClick={onClose} style={{ float: 'right', marginTop: 32, marginRight: 36 }}>
                        <CloseOutlinedIcon />
                    </IconButton>

                    <div style={{ marginTop: 59, marginLeft: 59 }}>
                        <div>
                            {integrationData.integrationType !== null && icon}
                            <Typography variant="integrationAddHeading" component="div" gutterBottom style={{ display: 'inline-block' }}>
                                <div>
                                    {t('integration_view.details')}
                                </div>
                            </Typography>
                            {isEditModeOn === undefined &&
                                <span>
                                    <MoreHorizIcon style={{ marginLeft: 150 }} onClick={(e) => { setIsShown(true); setAnchorEl(e.currentTarget); }} />
                                    <SelectItemList open={Boolean(isShown)} onClose={handleClose}
                                        items={rowActionMenuItems}
                                        anchorEl={anchorEl}
                                        isViewIntegration={true} />
                                </span>
                            }

                        </div>

                        <Divider style={{ marginBottom: 22 }} />
                            <div>
                                <div>
                                    <Typography variant="headingTextView" component="span" gutterBottom style={{ display: 'inline-block' }}>
                                        <span>
                                            {state.integrationData.licenseType}
                                        </span>
                                    </Typography>
                                        <span>
                                            {state.integrationData.licenseType === "Trial" ? (": "+trialText) : ""}
                                        </span>
                                </div>
                                <div>
                                    <Typography variant="headingTextView" component="span" gutterBottom style={{ display: 'inline-block' }}>
                                        <span>
                                            {t('integration_view.domain_invite_link')}
                                        </span>
                                    </Typography>
                                    <span id='domain_invite_link' style={{marginLeft: 71}}> {domainInviteLink.length >= 35 ? domainInviteLink.substring(0,35) + "..." :  domainInviteLink}</span>
                                    <ClickAwayListener onClickAway = {handleTooltipClose}>
                                    <CustomTooltip  title = {t('tooltip.copied')}
                                                    placement = 'top'
                                                    PopperProps={{disablePortal: true}}
                                                    onClose={handleTooltipClose}
                                                    open={open}
                                                    leaveDelay = '1000'
                                                    disableInteractive = 'true'
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener>
                                    <CustomCopyIcon sx = {{marginLeft: 71, transform: 'translateY(-25px)', color: click ? blue : black, '&:hover': {color :click ? blue: lightGray}}}
                                                    onClick={(e) => {navigator.clipboard.writeText(domainInviteLink); handleTooltipOpen(); handleClick();}}/>
                                    </CustomTooltip>
                                    </ClickAwayListener>
                                    <Divider style={{ marginBottom: 22, marginTop: 0 }}/>
                                </div>
                            </div>
                        {infos.map((info, index) => {
                            //in every json object 1 heading and an array of key/value pairs
                            let heading = Object.keys(info)[0];
                            let objectValue = Object.values(info)[0];
                            let attributeKeys = Object.keys(objectValue);
                            let subheadingNeeded = info[Object.keys(info)[6]];
                            //content object is different for each section
                            var contentObject;

                            let configurator = <Configurator isDomainIntegration={integrationData.domain !== null ? 'true' : 'false'} authenticationSettingsDto={integrationData.authenticationSettingsDto} shouldShowAccessKey = {!(protocol === "SAML" && !integrationData.domainVerified)} />

                            if (isMetadataUrl && heading === "SAML identity provider parameters") {
                                contentObject = <DownloadComponent
                                infoDescription={'Metadata URL'}
                                filePath="/app/downloadIdp"
                                fileName={idPMetadataName}
                                isLinkComponent={true} />
                            } else if (heading === "Federate your domain now") {
                                contentObject = <FederationComponent showGrantAccessButton={showGrantAccessButton}
                                    setShowGrantAccessButton={setShowGrantAccessButton}
                                    adminEmail={adminEmail}
                                    setAdminEmail={setAdminEmail}
                                    domain={state.integrationData.domain}
                                    isEdit={true}
                                    setDisabledFederateBtn={setDisabledFederateBtn}
                                    setDisabledManagedBtn={setDisabledManagedBtn}
                                    setDisabled={setDisabled}
                                    disabledFederateBtn={disabledFederateBtn}
                                    disabledManagedBtn={disabledManagedBtn}
                                    disabled={disabled}
                                    serverName={state.integrationData.serverName}
                                />
                            } else if (heading === "Contract") {
                                contentObject =  <ContractSection  setNewContractId={setNewContractId}
                                                                   identitiesLimit={identitiesLimit}
                                                                   setIdentitiesLimit={setIdentitiesLimit}
                                                                   integrationsLimit={integrationsLimit}
                                                                   setIntegrationsLimit={setIntegrationsLimit}
                                                                   contractReference={contractReference}
                                                                   setContractReference={setContractReference}
                                                                   selectedContract={selectedContract}
                                                                   setSelectedContract={setSelectedContract}
                                                                   assignContractChecked={assignContractChecked}
                                                                   setAssignContractChecked={setAssignContractChecked}
                                                                   contract={integrationData.contract}
                                                                   isEdit={isEditModeOn}/>
                            } else {
                                contentObject = (isEditModeOn && info.isConfigurator) ? configurator :
                                    <div>
                                        {
                                            attributeKeys.map(key => {
                                                let obj = objectValue[attributeKeys[key]];
                                                let type = obj["type"];
                                                let keysArray = Object.keys(obj).filter(key => key !== "type");
                                                return keysArray.map(val => {

                                                    //infoDescription for textfield - needed to be in this form
                                                    let infoDescription = {
                                                        "type": type !== undefined ? type[0] : ""
                                                    }
                                                    //conditions
                                                    let isCertificate = (type !== undefined && type[0] === 'certificate');
                                                    let isApplication = (val === "Application");

                                                    if (type === undefined && val === "Name") {
                                                        type = "name";
                                                    }

                                                    //if width is not defined, it'll take 100%
                                                    let textFieldWithCopyComponent =
                                                        <div>
                                                            <TextFieldCopy
                                                                width={'80%'}
                                                                infoDescription={infoDescription}
                                                                isOidc={integrationType === "OIDC"}
                                                                isCopied={info.isCopied}
                                                                isEditable={val !== "Integration Protocol" ? info.isEditable : false}
                                                                type={obj.type !== undefined ? obj.type : type}
                                                                value={heading === [protocol + t('integration_view.sp_parameters')] ? undefined : ((typeOfMetadata === "url" && !(type === "name")) ? integrationData.metadataUrl : obj[val])}
                                                                onChange={handleTextFieldChange}
                                                            />
                                                            {typeOfMetadata === "url" && isEditModeOn && heading === "Service provider metadata" && error &&
                                                                <div style={{ fontSize: '10px', marginLeft: -5, marginTop: '-25px' }}>
                                                                    <img src={errorIcon} alt={"error"} />
                                                                    <ErrorSpan>{errorMessage}</ErrorSpan>
                                                                </div>
                                                            }
                                                        </div>

                                                    let textViewComponent = <Typography variant="labelTextView" component="div" gutterBottom style={{ display: 'inline-block', width: '50%', overflowWrap: 'break-word' }}>
                                                        <div>
                                                            {obj[val]}
                                                        </div>
                                                    </Typography>;

                                                    //components for display

                                                    //component for certificate (if edit mode is on then download/copy content component, otherwise only download link component)
                                                    let integrationSectionItemCertificate = <IntegrationDetailsSectionItem heading={val} content={<DownloadComponent
                                                        infoDescription={val.toLowerCase}
                                                        filePath="/app/download"
                                                        fileName={idPCertificateName}
                                                        isLinkComponent={isEditModeOn ? false : true} />} divide={!isEditModeOn} />;

                                                    //component for text info to view or copy it
                                                    let integrationSectionItemTextViewOrCopy = <IntegrationDetailsSectionItem heading={val} isEditModeOn={isEditModeOn} content={
                                                        (isEditModeOn === true && !isApplication) ? textFieldWithCopyComponent : textViewComponent} divide={!isEditModeOn} sectionHeading={heading} checked={checked} checkedSiem={checkedSiem} scimEnabled={integrationData.scimEnabled} />;

                                                    if (info.isFileUpload && isEditModeOn) {
                                                        return metadata;
                                                    } else
                                                        if (!isApplication) {
                                                            return isCertificate ? integrationSectionItemCertificate : integrationSectionItemTextViewOrCopy;
                                                        } else {
                                                            return integrationSectionItemTextViewOrCopy;
                                                        }

                                                })
                                            })
                                        }
                                    </div>
                            }
                            return info.isVisible ? <IntegrationDetailsSection heading={heading} content={contentObject} isEditModeOn={isEditModeOn} divide={isEditModeOn} subheadingNeeded = {subheadingNeeded} checked = { heading === "Contract" ? assignContractChecked : checked} handleChangeSwitch = {handleChangeSwitch} /> : <></>
                        })}
                        {
                            isEditModeOn && <IntegrationDetailsSection heading = {t('add_integration.siem_heading')}
                                                                       content = {<SIEMSection
                                                                                        t={t}
                                                                                        inSidebar = {true}
                                                                                        checked = {checkedSiem}
                                                                                        selectedSiem = {selectedSiem}
                                                                                        setSelectedSiem = {setSelectedSiem}
                                                                                        values = {values}
                                                                                        errors = {errors}
                                                                                    />}
                                                                       isEditModeOn = {isEditModeOn}
                                                                       subheadingNeeded = {false}
                                                                       checked = {checkedSiem}
                                                                       handleChangeSwitch = {handleChangeSwitchSiem}
                                                                       divide={true}
                                                                       sx = {{paddingRight: '45px'}}/>
                        }
                        {
                            isEditModeOn &&
                            <div style={{ textAlign: 'right' }}>
                                <Button style={{ marginTop: '47px', marginRight: '10%' }}
                                        onClick={saveChanges}>{t('add_integration.save_changes_button_heading')}
                                </Button>
                            </div>
                        }

                    </div>
                </div>

            </Drawer>
            <ContractRemovalConfirmationDialog show={showContractRemovalDialog} setShow={setShowContractRemovalDialog} removeContract={removeContract} setRemoveContract={setRemoveContract} save={saveChanges}/>
        </>
    );
}
