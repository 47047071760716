import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

function ContractStats({ stats }) {
    return (
        <StatsContainer>
            {stats.map((stat, index) => (
                <StatItem key={index}>
                    <StatLabel>{stat.label}</StatLabel>
                    <StatValue>{stat.value}</StatValue>
                </StatItem>
            ))}
        </StatsContainer>
    );
}

const StatsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(1),
    width: "100%",
    alignItems: "start",
    gap: theme.spacing(1),
    flexWrap: "wrap"
}));

const StatItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minWidth: "100px"
}));

const StatLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Source Sans Pro, sans-serif"
}));

const StatValue = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontSize: "14px",
    marginTop: theme.spacing(0.5),
    fontFamily: "Source Sans Pro, sans-serif"
}));

export default ContractStats;