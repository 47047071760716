import * as React from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SubscriptionCard } from '../pages/subscription/SubscriptionCard';
import Api from "../helpers/service";

function ContractDropdown(props) {
    const { selectedContract, setSelectedContract, setNewContractId, createContract, showSelectedContract, setShowSelectedContract } = props

    console.log(selectedContract);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [contractData, setContractData] = React.useState([]);

    const open = Boolean(anchorEl);
    const api = new Api();

    const loadContractsForUser = () => {
        api.getContractsForUser("/app/getContractsForUser").then( data => {
            console.log(data);
            setContractData(data);
        });

    }
    const handleClick = (event) => {
        if (!createContract) {
            loadContractsForUser();
            setAnchorEl(event.currentTarget);
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ContractSelectorContainer $createContract={createContract}>
                <ContractSelectorLabel htmlFor="contract-select">Select Contract</ContractSelectorLabel>
                <SelectFieldButton
                    id="contract-select"
                    aria-controls={open ? "contract-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    $createContract={createContract}
                >
                    {selectedContract !== null ? (selectedContract.caption ? selectedContract.caption : selectedContract.contractId) : ""}
                    <SelectFieldIcon
                        loading="lazy"
                        alt="Contract selection icon"
                    />
                </SelectFieldButton>
            <StyledMenu
                id="subscription-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'subscription-button',
                }}
            >
                {contractData.map((contract) => (
                    <MenuItem key={contract.contractId} onClick={handleClose} disableRipple>
                        <SubscriptionCard contract={contract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} closeIconNeeded={false} titleNeeded={true} contractLabelNeeded={false} setShowSelectedContract={setShowSelectedContract} />
                    </MenuItem>
                ))}
            </StyledMenu>
            </ContractSelectorContainer>
            {showSelectedContract &&
                <SubscriptionCard contract={selectedContract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId}
                               closeIconNeeded={true} titleNeeded={false} contractLabelNeeded={false} />}
        </>
    );
}

const ContractSelectorContainer = styled("div")(({ $createContract }) => ({
    display: "flex",
    maxWidth: "592px",
    flexDirection: "column",
    fontFamily: "Source Sans Pro, sans-serif",
    opacity: $createContract ? 0.8 : 1,
    marginTop: 15
}));

const ContractSelectorLabel = styled("label")({
    width: "100%",
    fontSize: "14px",
    color: "var(--Gray-6, #7e7e7e)",
    fontWeight: 600,
    letterSpacing: "0.14px",
    lineHeight: 1,
    marginBottom: "4px",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const SelectFieldButton = styled(Button)(({ $createContract }) => ({
    disabled: $createContract,
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px solid var(--Gray-3, #d7d7d7)",
    background: "var(--White, #fff)",
    padding: "12px",
    gap: "8px",
    width: "100%",
    fontSize: "18px",
    color: "var(--Black, #000)",
    fontWeight: 400,
    letterSpacing: "0.09px",
    lineHeight: 1,
    cursor: "pointer",
    textAlign: "left",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:focus": {
        outline: "1px solid var(--Gray-3, #d7d7d7)",
        outlineOffset: "2px",
    },
    "@media (max-width: 991px)": {
        maxWidth: "100%",
        whiteSpace: "initial",
    },
}));

const SelectFieldIcon = styled(KeyboardArrowDownIcon)({
    aspectRatio: 1,
    objectFit: "contain",
    objectPosition: "center",
    width: "24px",
    marginLeft: "auto",
});

const StyledMenu = styled(Menu)({
    '& .MuiPaper-root': {
        borderRadius: '0 0 8px 8px',
        marginTop: '4px',
        maxWidth: '592px',
        border: '1px solid #d7d7d7',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '100%'
    },
    '& .MuiMenuItem-root': {
        width: '100%',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

export default ContractDropdown;