import * as React from "react";
import { Button, Box, Menu, MenuItem } from "@mui/material";
import AddIconMUI from '@mui/icons-material/Add';
import { styled } from "@mui/material/styles";

function AddValueButton({ anchorEl, onMenuClick, onMenuClose, onValueSelect, menuOptions }) {
    return (
        <>
            <StyledButton
                onClick={onMenuClick}
                aria-controls={Boolean(anchorEl) ? 'value-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
            >
                <AddIcon/>
                <AddText>Add value</AddText>
            </StyledButton>

            <Menu
                id="value-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'add-value-button',
                }}
            >
                {menuOptions.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => onValueSelect(option)}
                        sx={{
                            fontFamily: 'Source Sans Pro, sans-serif',
                            fontSize: '16px'
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: "24px",
    alignSelf: "flex-start",
    display: "flex",
    marginTop: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.primary.main,
    textAlign: "center",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "17px",
    fontWeight: 600,
    lineHeight: 1,
    textTransform: "none",
    padding: theme.spacing(1)
}));

const AddIcon = styled(AddIconMUI)({
    aspectRatio: "1",
    objectFit: "contain",
    objectPosition: "center",
    width: "24px"
});

const AddText = styled(Box)({
    textDecoration: "underline"
});

export default AddValueButton;